import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from "gatsby"

import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

import Layout from '../../layout/layout'
import LogoSNN from '../../static/images/logo_snn.png'
import Collaboration from '../../svg/retailer-collaboration.svg'

export const query = graphql`
    query DoMyMyFile {
        stageFdFile: file(base: {eq: "stage-frontend-developer.pdf"} ) {
            publicURL
        }
        vacancyCmFile: file(base: {eq: "vacature-contentmarketeer.pdf"} ) {
            publicURL
        }
    }
`


export default GoogleApiWrapper({ apiKey: "AIzaSyBqGVNOk9GBHGAmB-60iAOMZKRredkHAWw" })(({ google, data }) => {
    return (
        <Layout>

            <Helmet>
            <title>Zupr - Over ons</title>
            <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
            </Helmet>

            <section className="text-default divider">
                <div className="inner">

                    <div className="text">
                        <h2>Wij maken producten lokaal vindbaar</h2>
                        <p>Als Zupr hebben we één doel; zorgen dat elke retailer en elk merk, groot of klein, dezelfde kansen krijgt. Zodat we met elkaar lokale economieën  versterken. Wij vinden dat er een waardevolle rol voor de fysieke winkel is, maar dat deze op de juiste manier moet worden ontsloten naar de consument. De eerste stap is dat het assortiment van elke winkel online zichtbaar en vindbaar is voor de consument. Dat is waar we dagelijks mee bezig zijn en wat richting geeft aan onze productontwikkeling.</p>
                        <h3>Ons team</h3>
                        <p>Zupr is een jong bedrijf waar nu al 4 jaar hard gebouwd wordt aan het Zupr platform en de bijbehorende infrastructuur. Onze mensen hebben jarenlange ervaring in software development, user experience design en bijvoorbeeld marketing & sales. Dat is belangrijk maar wat we vooral belangrijk vinden is dat we plezier hebben in ons werk. Alleen dan kom je tot goede resultaten als team.</p>
                    </div>

                </div>
            </section>

            {/* <section className="text-left top-align">
                <div className="inner">

                    <div className="text">
                        <h2>Contact</h2>
                        <h3>Adres</h3>
                        <p>Helperpark 276-8<br />9723 ZA Groningen<br /><a href="https://www.google.com/maps/dir/?api=1&amp;destination=Helperpark+276-8,9723+ZA&amp;travelmode=driving" target="_blank" rel="noopener noreferrer">Plan route</a></p>
                        <h3>Contactgegevens</h3>
                        <p><a href="mailto:info@zupr.nl" className="email">info@zupr.nl</a><br /><a href="tel:+31854011306" className="telephone">+31 (0)85 40 11 306</a></p>
                    </div>

                    <div className="image map">
                        <Map
                            google={google}
                            zoom={17}
                            initialCenter={{
                                lat: 53.2195888,
                                lng: 6.5643632,
                            }}
                            mapTypeControl={false}
                            streetViewControl={false}
                            fullscreenControl={false}>
                            <Marker
                                name="Zupr"
                                position={{
                                    lat: 53.2195888,
                                    lng: 6.5643632,
                                }}
                            />
                        </Map>
                    </div>

                </div>
            </section> */}

            <section className="grey text-right">
                <div className="inner">

                    <div className="text">
                        <h2>Werken bij</h2>
                        <p>Wil je bij een snel groeiende en financieel gezonde startup werken, die de manier waarop mensen lokaal producten zoeken en vinden voor altijd gaat veranderen?</p>
                        <p>Werken bij Zupr betekent veel vrijheid, ruimte om je talent te benutten en ook een geweldige werkplek met een goede sfeer. Heb je interesse?</p>
                        <p>Bekijk onze vacatures of stuur een open sollicitatie voorzien van motivatie en CV. De voertaal bij ons op kantoor is Nederlands. Volg je nog een studie? Ook voor een stage ben je van harte welkom!</p>
                        <h3>Vacatures</h3>
                        <ul className="vacancy">
                            <li>Front-end developer <span><a href="mailto:hr@zupr.nl?subject=Zupr vacature Front-end developer">Solliciteren</a></span></li>
                            <li>Stage en afstuderen <span><a rel="noopener noreferrer" target="_blank" href={data.stageFdFile.publicURL}>Bekijk vacature</a> of <a href="mailto:hr@zupr.nl?subject=Zupr vacature Stage en afstuderen">solliciteren</a></span></li>
                        </ul>
                    </div>
                    <div className="image about-work-visual" />
                </div>
            </section>

            <section className="text-default">
                <div className="inner">
                    <div className="text no-pad-bottom">
                        <h2>Onze roadmap & COVID-19</h2>
                        <p>De infrastructuur van Zupr is continue in ontwikkeling. Voor een optimale ondersteuning van de retailers hebben de ontwikkelingen rondom COVID-19 ons doen besluiten onze roadmap aan te passen. Zo is er eind afgelopen jaar hard gewerkt aan functionaliteit voor winkelen op afspraak, producten reserveren en uiteraard <a href="https://support.zupr.nl/hc/nl/articles/360019324740-Online-betalen-en-afhalen-in-de-winkel-Click-Collect-" target="_blank" rel="noopener noreferrer">Click & Collect</a>. Ook is er een koppeling gerealiseerd met het <a target="_blank" rel="noopener noreferrer" href="https://veloyd.nl">Veloyd platform</a>, zodat meer koeriersdiensten gekoppeld kunnen worden en consumenten optimaal geinformeerd worden over de status van hun bezorging.</p>
                        <p>Begin dit jaar is tevens de productclassificatie uitgerold welke gebruikers in staat stelt om eenvoudiger producten van retailers te vinden. Middels de <a href="/nl/quickstart/">quickstart pagina</a> is het voor verkooppunten eenvoudig te zien op welke wijze het assortiment eenvoudig ingevoerd en beheerd kan worden middels het Zupr platform. Intussen kunnen we ons weer richten op de functionaliteit welke onderdeel uitmaakt van onze visie; deze is immers om juist meer consumenten in de fysieke winkel te krijgen.</p>
                        <h2>Wat staat er op de planning?</h2>
                        <p>Recent is de <a target="_blank" rel="noopener noreferrer" href="https://warenhuis.groningen.nl/locaties/kaart">kaartweergave</a> voor winkelgebieden gelanceerd en is het middels de locatieclassificatie mogelijk om eenvoudig te filteren op winkels en producten. Een samenwerking met <a target="_blank" rel="noopener noreferrer" href="https://www.ccv.eu/nl/">CCV</a> en <a target="_blank" rel="noopener noreferrer" href="https://www.groupcard.nl">GroupCard</a> biedt <a href="/nl/winkelgebieden/">winkelgebieden</a> de mogelijkheid tot het faciliteren van stadspassen en cadeaukaarten. Hierdoor kunnen consumenten extra gestimuleerd worden tot het doen van lokale aankopen.</p>
                    </div>

                </div>
            </section>

            <section className="text-left divider image-bottom">
                <div className="inner">

                    <div className="text">
                        <p>Een belangrijke wijziging welke ook komende periode op de planning staat is het verbeteren van de assortiment-pagina voor verkooppunten en de mogelijkheid om meer tekstuele informatie toe te voegen, zoals een Over ons pagina. Hierdoor kan de locatiepagina op het Zupr platform door een verkooplicatie gebruikt worden als een volledige website- of webshop-oplossing.</p>
                        <small>Zupr is transparant in de afwegingen die gemaakt worden ten aanzien van de roadmap en daarbij hebben we altijd intensief contact met alle stakeholders. Voor vragen over de roadmap en/of gewenste features kunt u uiteraard contact opnemen.</small>
                    </div>

                    <div className="image">
                        <Collaboration />
                    </div>

                </div>
            </section>

            <section className="text-right">
                <div className="inner">
                    <div className="text">
                        <h2>VIA 2020 softwareontwikkeling</h2>
                        <small>25 augustus 2021</small>
                        <p>De afgelopen periode heeft Zupr gebruik mogen maken van de VIA 2020 regeling. Voor vragen of toelichting kunt u contact opnemen met het Zupr team. Op aanvraag is het projectplan, de rapportage en bijbehorende urenverantwoording beschikbaar. Lees <a href="/nl/via-2020-softwareontwikkeling/">hier</a> meer over de inhoud van dit project.</p>
                    </div>
                    <div className="image">
                        <img width="100%" src={LogoSNN} alt="Logo SNN" title="Logo SNN" />
                    </div>
                </div>
            </section>

        </Layout>
    );
});
